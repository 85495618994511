import React, {Component, MouseEvent} from "react"
import {Link} from "react-router-dom";
import {motion} from 'framer-motion'

import MovieIcon from '@material-ui/icons/Movie';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import "../../sass/dashboard.sass"
import {data, iProject, iShot} from "../web"
import {Player} from './player'

interface iprops {
}

interface istate {
    shots: iShot[]
    current_slide: number
    slides: string[][]
}

export class Dashboard extends Component<iprops, istate>
{
    private reel_2020: Player
    private reel_2021: Player

    constructor(props: iprops) {
        super(props)

        const slides = [
            this.get_shot_image('super_m', 16),
            this.get_shot_image('el_refugio', 18),
            this.get_shot_image('rotatecno', 0),
            this.get_shot_image('el_ojo_de_la_vaca', 0),
            this.get_shot_image('nino_rojo', 4),
            this.get_shot_image('el_refugio', 13),
            this.get_shot_image('el_refugio', 20),
            this.get_shot_image('el_refugio', 21),
            this.get_shot_image('ser_eclipse', 0),
        ]

        const count = slides.length

        var current_slide: number = Math.round(Math.random() * count)

        if (current_slide > (count - 1))
            current_slide = (count - 1)
        else if (current_slide < 0)
            current_slide = 0

        this.state = {
            shots: this.get_shots(),
            current_slide: current_slide,
            slides: slides
        }
    }

    get_shot_image = (name: string, shot_index: number) => {

        const project = this.get_project_by_name(name)
        const shot = project.shots[shot_index]

        return [name, shot.project_title, shot.image_dst]
    }

    get_project_by_name = (name: string): iProject => {

        for (let i = 0; i < data.projects.length; i++) {
            const project = data.projects[i]
            if (project.name == name)
                return project
        }

        return null
    }

    shuffle = (shots: iShot[]) => {
        for (let i = shots.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shots[i], shots[j]] = [shots[j], shots[i]];
        }

        return shots;
    }

    get_shots = (): iShot[] => {

        let shots: iShot[] = []

        const superm = this.get_project_by_name('super_m')
        shots.push(superm.shots[17])
        shots.push(superm.shots[14])
        shots.push(superm.shots[11])
        shots.push(superm.shots[12])

        const rotatecno = this.get_project_by_name('rotatecno')
        shots.push(rotatecno.shots[0])

        const lusers = this.get_project_by_name('lusers')
        shots.push(lusers.shots[3])

        const guerrero = this.get_project_by_name('guerrero')
        shots.push(guerrero.shots[0])
        shots.push(guerrero.shots[4])

        const lol = this.get_project_by_name('lol')
        shots.push(lol.shots[3])
        shots.push(lol.shots[0])

        const lee = this.get_project_by_name('lee')
        shots.push(lee.shots[2])

        const diablo_con_angel = this.get_project_by_name('un_diablo_con_angel')
        shots.push(diablo_con_angel.shots[0])

        const jumpcats = this.get_project_by_name('jumpcats')
        shots.push(jumpcats.shots[0])

        const nino_rojo = this.get_project_by_name('nino_rojo')
        shots.push(nino_rojo.shots[4])
        shots.push(nino_rojo.shots[10])

        const el_refugio = this.get_project_by_name('el_refugio')
        shots.push(el_refugio.shots[0])
        shots.push(el_refugio.shots[13])
        shots.push(el_refugio.shots[15])
        shots.push(el_refugio.shots[18])
        shots.push(el_refugio.shots[20])
        shots.push(el_refugio.shots[21])

        const ser_eclipse = this.get_project_by_name('ser_eclipse')
        shots.push(ser_eclipse.shots[0])

        const tall_girl = this.get_project_by_name('tall_girl_2')
        shots.push(tall_girl.shots[7])

        const necro = this.get_project_by_name('necrolovers')
        shots.push(necro.shots[0])

        return shots
    }

    get_shuffle_shots = (): iShot[][] => {

        let shots = this.state.shots
        shots = this.shuffle(shots)

        let shots_lines: iShot[][] = []

        shots_lines.push([shots[1], shots[2], shots[3], shots[4]])
        shots_lines.push([shots[5], shots[6], shots[7]])
        shots_lines.push([shots[8], shots[9], shots[10], shots[11]])
        shots_lines.push([shots[12], shots[13], shots[14]])
        shots_lines.push([shots[15], shots[16], shots[17], shots[18]])
        shots_lines.push([shots[19], shots[20], shots[21]])

        return shots_lines
    }

    change_image = (index: number) =>
    {
        this.setState({current_slide: index})
    }

    render() {
        return (
            <motion.div className="dashboard"
                initial={{opacity: 0}}
                exit={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: .4}}>
                <div className="slider">
                    <img src={data.static + '/' + this.state.slides[this.state.current_slide][2]} />
                    <div className='info_box'>
                        <h1>COMPOSITOR & C++ / PYTHON developer</h1>
                        <h2>More than <p className='number'>13</p> years working in the <p>VFX INDUSTRY</p></h2>
                    </div>
                    <div className='play'>
                        <button onClick={() => this.reel_2021.show()}>
                            <h1><p>REEL</p> 2021</h1>
                            <PlayArrowIcon />
                        </button>
                        <button onClick={() => this.reel_2020.show()}>
                            <h1><p>REEL</p> 2010 - 2020</h1>
                            <PlayArrowIcon />
                        </button>
                    </div>
                    <ul className='buttons'>
                        {
                            this.state.slides.map((_: string[], index: number) =>
                                <button key={index}
                                    value={this.state.current_slide == index ? 'checked' : ''}
                                    onClick={()=>this.change_image(index)} />
                            )
                        }
                    </ul>
                    <li className='project_link'>
                        {
                            this.state.slides.map((project: string[], index: number) =>
                                this.state.current_slide == index &&
                                <Link key={index} to={"/projects/" + project[0]}>
                                    {project[1].toUpperCase()}
                                </Link>
                            )
                        }
                    </li>
                </div>
                <div className="shots">
                    {
                        this.get_shuffle_shots().map((shot_list: iShot[], index: number) =>
                            <ul key={'shot_list' + index}>
                                {
                                    shot_list.map((shot: iShot, index2: number) =>
                                        <li key={'shot' + index2}>
                                            <Link to={"/projects/" + shot.project_name}>
                                                <img src={shot.image_dst ? data.static + '/' + shot.image_dst
                                                    : data.static + '/images/not_found.jpg'} />
                                                <div className='info_project'>
                                                    <h1>{shot.project_title.toUpperCase()}</h1>
                                                    <h2>{shot.project_shots}{!shot.exactshots && '+'} SHOTS</h2>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>
                        )
                    }
                </div>
                <Player
                    ref={player => this.reel_2020 = player}
                    video={data.static + '/reel_2020.mp4'} />

                <Player
                    ref={player => this.reel_2021 = player}
                    video={data.static + '/reel_2021.mp4'} />
            </motion.div>
        )
    }
}
