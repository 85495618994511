import React, {Component} from "react"
import ReactDOM from "react-dom"
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom"

import "../sass/main.sass"

import {Header} from './components/header'
import {Nav} from './components/nav'
import {Footer} from './components/footer'

import {Dashboard} from './components/dashboard'
import {Blog} from './components/blog'
import {Projects} from './components/projects'
import {Development} from './components/development'
import {Skill} from './components/skill'
import {TimeLine} from './components/time_line'
import {Downloads} from './components/downloads'

interface istate {
}

export class Web extends Component<any, istate> {
    private years: number[];
    private tags: string[];
    private nav: Nav
    private header: Header

    constructor(props: any) {
        super(props)

        this.years = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2020, 2021, 2022, 2023]
        this.tags = ['all', 'film', 'shortfilm', 'series', 'advertsing', 'international', 'misc']
    }

    render() {
        return (
            <div className='main' onClick={() => {
                this.header.forceUpdate()
                this.nav.forceUpdate()
            }}>
                <Header ref={header => this.header = header} />
                <Router>
                    <Nav ref={nav => this.nav = nav} />

                    <div className="content">
                        <Switch>
                            <Route path="/dashboard">
                                <Dashboard />
                            </Route>
                            {
                                data.projects.map((project: iProject) =>
                                    <Route key={project.name} path={"/projects/" + project.name}>
                                        <Projects project={project} />
                                    </Route>
                                )
                            }

                            {
                                this.tags.map((_tag: string) =>
                                    <Route key={_tag} path={"/projects/" + _tag}>
                                        <Projects tag={_tag} tags={this.tags} />
                                    </Route>
                                )
                            }

                            <Route path="/projects">
                                <Projects tag='all' tags={this.tags} />
                            </Route>

                            <Route path="/development">
                                <Development />
                            </Route>

                            <Route path="/skill">
                                <Skill />
                            </Route>

                            <Route path="/blog">
                                <Blog />
                            </Route>

                            {
                                this.years.map((year: number) =>
                                    <Route key={year} path={"/timeline/" + year} >
                                        <TimeLine years={this.years} year={year} />
                                    </Route>
                                )
                            }

                            <Route path="/timeline">
                                <TimeLine years={this.years} year={0} />
                            </Route>

                            <Route path="/downloads">
                                <Downloads />
                            </Route>

                            <Route path="/">
                                <Dashboard />
                            </Route>
                        </Switch>
                    </div>
                </Router>

                <Footer />
            </div>
        )
    }
}


export interface iShotInfoArtist {
    name: string
    work: string[]
}

interface iShotInfo {
    priority: number
    description: string
    my_work: string[]
    softwares: string[]
    resources: string[]
    artists: iShotInfoArtist[]
}

export interface iShot {
    image_src: string
    image_dst: string
    image_cg: string
    video_original: string
    video_breakdown: string
    video_result: string
    project_title: string
    project_shots: number
    project_name: string
    exactshots: boolean
    info: iShotInfo
}

interface iProjectInfo {
    title: string
    type: string
    tags: string[]
    director: string
    producers: string[]
    country: string
    description: string
    shots: number
    exactshots: boolean
    priority: number
    year: number
    status: string
}

export interface iProject {
    name: string
    shots: iShot[]
    cover: string
    slide: string
    video: string
    year: number
    info: iProjectInfo
}

export interface iBlog {
    name: string
    title: string
    subtitle: string
    think: string
    link: string
    download: string
    date: string
    thumbnails: number
    showing: string
}

interface iData {
    static: string,
    projects: iProject[]
    blog: iBlog[]
}

export let data: iData;
data = {
    static: window.location.origin + '/static',
    projects: [],
    blog: []
}

Promise.all([
    fetch(window.location.origin + '/static/projects/projects.json'),
    fetch(window.location.origin + '/static/blog/blog.json')
])
    .then(([projectsResponse, blogResponse]) => Promise.all([projectsResponse.json(), blogResponse.json()]))
    .then(([projects, blog]) => {
        data.projects = projects
        data.blog = blog

        const root = document.body.querySelector(".root")
        ReactDOM.render(<Web />, root)
    })
