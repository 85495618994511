import React, {Component} from "react"
import {NavLink} from "react-router-dom";
import {motion} from 'framer-motion'

import "../../sass/time_line.sass"
import {ProjectItem} from './project_item'
import {data, iProject} from "../web"


interface iprops {
    year: number
    years: number[]
}

interface istate {
}

export class TimeLine extends Component<iprops, istate>
{
    constructor(props: iprops) {
        super(props)
    }

    get_projects_by_year = (year: number): iProject[] => {

        let filter_project: iProject[] = []

        data.projects.map((project: iProject) => {
            if (project.year == year)
                filter_project.push(project)
        })

        return filter_project
    }

    render() {
        return (
            <motion.div
                initial={{opacity: 0}}
                exit={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: .4}}
                className="time_line">
                <ul>
                    {
                        this.props.years.map((year: number) =>
                            <li key={year}>
                                <NavLink to={"/timeline/" + year}>
                                    <ul>
                                        {this.get_projects_by_year(year).map((project: iProject, index: number) =>
                                            <li key={project.name}>{(index + 1) + ' - ' + project.info.title}</li>
                                        )}
                                    </ul>
                                    <h1>{year}</h1>
                                </NavLink>
                            </li>
                        )
                    }
                </ul>
                <div className="project_list">
                    {
                        this.get_projects_by_year(this.props.year).map((project: iProject) =>
                            <ProjectItem key={project.name} project={project} />
                        )
                    }
                </div>
            </motion.div>
        )
    }
}
