import React, {Component} from "react"

import "../../sass/development.sass"

interface iprops {
}
interface istate {
}

export class Development extends Component<iprops, istate>
{
    constructor(props: iprops) {
        super(props)
    }

    render() {
        return (
            <div className="development">
                development
            </div>
        )
    }
}
