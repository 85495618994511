import React, {Component} from "react"

import StyleIcon from '@material-ui/icons/Style';
import SchoolIcon from '@material-ui/icons/School';
import FilterTiltShiftIcon from '@material-ui/icons/FilterTiltShift';
import VideocamIcon from '@material-ui/icons/Videocam';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';

import "../../sass/skill.sass"

interface iprops {
}
interface istate {
}

export class Skill extends Component<iprops, istate>
{
    constructor(props: iprops) {
        super(props)
    }

    render() {
        return (
            <div className="skill">
                <div>
                    <h1><StyleIcon/>SPECIALTY</h1>
                    <div>
                        <div>
                            <h2><FilterTiltShiftIcon/>Nuke Compositing: ( 13 years of experience )</h2>
                            <li>- Tracking 2D y 3D</li>
                            <li>- Rotoscoping</li>
                            <li>- Understanding of light and color</li>
                            <li>- BlinkScript Programming</li>
                            <li>- Python Programming</li>
                            <li>- PySide QT</li>
                            <li>- Etc...</li>
                        </div>
                        <div>
                            <h2><FilterTiltShiftIcon/>Programming: ( 10 years of experience )</h2>
                            <p>Creation of tools and plugins to make the composition flow easier and faster ( Nuke )</p>
                            <li>- Python</li>
                            <li>- C++</li>
                            <li>- Shell Script (Linux)</li>
                        </div>
                    </div>
                </div>
                <div>
                    <h1><StyleIcon/>COMPLEMENTARY SKILL</h1>
                    <div>
                        <div>
                            <h2><FilterTiltShiftIcon />3D Softwares: ( 10 years of experience )</h2>
                            <li>- Houdini: general knowledge</li>
                            <li>- Autodesk Maya: general knowledge</li>
                            <li>- Autodesk Arnold: general knowledge</li>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2><FilterTiltShiftIcon />Pipeline: </h2>
                            <li>- Autodesk ShotGrid</li>
                            <li>- Ftrack</li>
                            <li>- Deadline: (Usage and API management)</li>
                        </div>
                    </div>
                </div>
                <div>
                    <h1><SchoolIcon/>STUDIES</h1>
                    <div>
                        <div>
                            <h2><FilterTiltShiftIcon/>Audiovisual technique ( DuocUC )</h2>
                            <li>- <VideocamIcon/> Camera</li>
                            <li>- <WbIncandescentIcon/> Illumination</li>
                        </div>
                        <div>
                            <h2><FilterTiltShiftIcon/>Autodesk Maya Diploma ( Academia Mac )</h2>
                            <li>- 3D Modeling</li>
                            <li>- 3D Animation</li>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
