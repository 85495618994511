import React, {Component} from "react"
import {Link} from "react-router-dom";

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FlagIcon from '@material-ui/icons/Flag';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import "../../sass/project_item.sass"
import {data, iProject} from "../web"


interface iprops {
    project: iProject
}

interface istate {
    img: string
    loaded: boolean
    production: boolean,
    release: boolean
}

export class ProjectItem extends Component<iprops, istate>
{
    constructor(props: iprops) {
        super(props)

        const status = props.project.info.status

        this.state = {
            img: data.static + '/' + this.props.project.cover,
            loaded: false,
            production: status == 'production' ? true : false,
            release: status == 'norelease' || status == 'production' ? false : true
        }
    }

    componentDidMount() {
        if (this.state.production) {
            this.setState({loaded: true})
            return
        }

        // espera que carguen las imagenes
        const img = new Image();

        img.src = this.state.img
        img.onload = () => {
            this.setState({loaded: true})
        }
    }

    render() {
        return (
            <li className="project_item" text={this.state.release ? '' : 'norelease'}>
                { this.state.loaded ?
                    <Link to={this.state.release ? "/projects/" + this.props.project.name : '#'}>
                        <img src={this.state.production ? data.static + '/images/production.jpg' : this.state.img} />
                        <div className='title'>
                            <h1>{this.props.project.info.title ? this.props.project.info.title.toUpperCase() : 'None'}</h1>
                            <div><RecordVoiceOverIcon /><h2>DIRECTOR:</h2> <p>{this.props.project.info.director ? this.props.project.info.director : '...'}</p></div>
                            <div><FormatListBulletedIcon/><h2>SHOTS:</h2> <p>{this.props.project.info.shots}{!this.props.project.info.exactshots && '+'}</p></div>
                            <div><FlagIcon/><h2>COUNTRY:</h2> <p>{this.props.project.info.country}</p></div>
                        </div>
                    </Link>
                    :
                    <div className='loading'>
                        <div>
                            <AutorenewIcon/>
                            <h1>LOADING</h1>
                        </div>
                    </div>
                }
            </li>
        )
    }
}
