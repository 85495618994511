import React, {Component} from "react"

import "../../sass/header.sass"
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

interface iprops {
}
interface istate {
}

export const imdb_icon = <svg className='imdb' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M350.5 288.7c0 5.4 1.6 14.4-6.2 14.4-1.6 0-3-.8-3.8-2.4-2.2-5.1-1.1-44.1-1.1-44.7 0-3.8-1.1-12.7 4.9-12.7 7.3 0 6.2 7.3 6.2 12.7v32.7zM265 229.9c0-9.7 1.6-16-10.3-16v83.7c12.2.3 10.3-8.7 10.3-18.4v-49.3zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zM21.3 228.8c-.1.1-.2.3-.3.4h.3v-.4zM97 192H64v127.8h33V192zm113.3 0h-43.1l-7.6 59.9c-2.7-20-5.4-40.1-8.7-59.9h-42.8v127.8h29v-84.5l12.2 84.5h20.6l11.6-86.4v86.4h28.7V192zm86.3 45.3c0-8.1.3-16.8-1.4-24.4-4.3-22.5-31.4-20.9-49-20.9h-24.6v127.8c86.1.1 75 6 75-82.5zm85.9 17.3c0-17.3-.8-30.1-22.2-30.1-8.9 0-14.9 2.7-20.9 9.2V192h-31.7v127.8h29.8l1.9-8.1c5.7 6.8 11.9 9.8 20.9 9.8 19.8 0 22.2-15.2 22.2-30.9v-36z"/></svg>


export class Header extends Component<iprops, istate>
{
    constructor(props: iprops) {
        super(props)
    }

    render() {
        let dashboard_text = ''
        const pathname = window.location.pathname

        if (pathname == '/' || pathname == '/dashboard')
            dashboard_text = 'dashboard'

        return (
            <div className="header" text={dashboard_text}>
                <div>
                    <DoubleArrowIcon className='logo'/>
                    <h1>Francisco Contreras</h1>
                    <h3> - VFX <p>ARTIST</p> - SENIOR<p> COMPOSITOR</p></h3>
                    <div className='social'>
                        <a target='_blank' href='https://cl.linkedin.com/in/francisco-contreras-31603018b'>
                            <LinkedInIcon className="linkedin_icon"/>
                        </a>
                        <a target="_blank" href="https://github.com/vinavfx">
                            <GitHubIcon/>
                        </a>
                        <a target='_blank' href='https://www.youtube.com/channel/UC8AuIkfEHTxE9zrWBbHrY4A'>
                            <YouTubeIcon className="facebook_icon"/>
                        </a>
                        <a target='_blank' href='https://www.imdb.com/name/nm9612037/?ref_=fn_nm_nm_14'>
                            {imdb_icon}
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
