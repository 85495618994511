import React, {Component} from "react"

import GitHubIcon from '@material-ui/icons/GitHub';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import GroupIcon from '@material-ui/icons/Group';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import {imdb_icon} from './header'

import "../../sass/footer.sass"

interface iprops {
}
interface istate {
}

export class Footer extends Component<iprops, istate>
{
    constructor(props: iprops) {
        super(props)
    }

    render() {
        return (
            <div className="footer">
                <div>
                    <div className='contact'>
                        <h1><PermContactCalendarIcon/>CONTACT</h1>
                        <a target='_blank' href='https://cl.linkedin.com/in/francisco-contreras-31603018b'><LinkedInIcon />Contact me on linkedin</a>
                    </div>
                    <div className='social'>
                        <h1><GroupIcon/>SOCIAL MEDIA</h1>
                        <a target='_blank' href='https://cl.linkedin.com/in/francisco-contreras-31603018b'><LinkedInIcon />LinkedIn</a>
                        <a target="_blank" href="https://github.com/vinavfx" ><GitHubIcon className='github_icon' />GitHub</a>
                        <a target='_blank' href='https://www.youtube.com/channel/UC8AuIkfEHTxE9zrWBbHrY4A'> <YouTubeIcon className="facebook_icon"/>YouTube</a>
                        <a target='_blank' href='https://www.imdb.com/name/nm9612037/?ref_=fn_nm_nm_14'>
                            {imdb_icon}
                            IMDb
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
