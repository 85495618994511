import React, {Component} from "react"

import {NavLink} from "react-router-dom";
import {motion} from 'framer-motion'
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LanguageIcon from '@material-ui/icons/Language';
import LiveTvIcon from '@material-ui/icons/LiveTv';

import "../../sass/projects.sass"
import {ProjectItem} from './project_item'
import {data, iProject} from "../web"
import {Project} from './project'
import TelegramIcon from '@material-ui/icons/Telegram'
import CameraRollIcon from '@material-ui/icons/CameraRoll';
import AppsIcon from '@material-ui/icons/Apps';
import GrainIcon from '@material-ui/icons/Grain';

interface iprops {
    project?: iProject
    tag?: string
    tags?: string[]
}

interface istate {
}

export class Projects extends Component<iprops, istate>
{
    constructor(props: iprops) {
        super(props)
    }

    get_projects_by_tag = (tag: string): iProject[] => {

        const projects = [...data.projects]
        let filter_project: iProject[] = []

        if (this.props.tag == 'all')
            filter_project = projects
        else
            projects.map((project: iProject) => {
                if (project.info.tags)
                    if (project.info.tags.includes(tag))
                        filter_project.push(project)
            })

        filter_project.sort((a, b) => (a.info.priority < b.info.priority) ? 1 : -1)

        return filter_project
    }

    componentDidMount= ()=> {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <motion.div
                initial={{opacity: 0}}
                exit={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: .4}}
                className="projects">
                { this.props.project && <Project project={this.props.project} />}

                <ul className="tags_list">
                    {
                        this.props.tags && this.props.tags.map((tag: string) =>
                            <li key={tag}>
                                <NavLink to={"/projects/" + tag}>
                                    {tag == 'all' && <AppsIcon />}
                                    {tag == 'film' && <GroupWorkIcon />}
                                    {tag == 'shortfilm' && <CameraRollIcon />}
                                    {tag == 'series' && <LiveTvIcon />}
                                    {tag == 'advertsing' && <TelegramIcon />}
                                    {tag == 'international' && <LanguageIcon />}
                                    {tag == 'misc' && <GrainIcon />}
                                    {tag.toUpperCase()}
                                </NavLink>
                            </li>
                        )
                    }
                </ul>
                <ul className="projects_list">
                    { !this.props.project &&
                        this.get_projects_by_tag(this.props.tag).map((project: iProject, index: number) =>
                            <ProjectItem key={index} project={project} />
                        )
                    }
                </ul>
            </motion.div>
        )
    }
}
