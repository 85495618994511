import React, {Component} from "react"

import "../../sass/player.sass"

interface iprops {
    video: string
}
interface istate {
    visibility: string
}

export class Player extends Component<iprops, istate>
{
    private video: HTMLVideoElement

    constructor(props: iprops) {
        super(props)

        this.state = {
            visibility: "hidden"
        }
    }

    show = () => {
        this.setState({visibility: "visible"})
        this.video.play()
    }

    hide = (e: any) => {
        if (e.target.tagName != "VIDEO")
            this.setState({visibility: "hidden"})

        this.video.pause()
    }

    render() {
        return (
            <div onClick={this.hide} className="player"
                //@ts-ignore
                style={{visibility: this.state.visibility}}>
                <video 
                    ref={video => this.video = video}
                    loop
                    controls
                    key={this.props.video}>

                    <source type="video/mp4" src={this.props.video} />
                </video>
            </div>
        )
    }
}
