import React, {Component, MouseEvent} from "react"

import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import GestureIcon from '@material-ui/icons/Gesture';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CompareIcon from '@material-ui/icons/Compare';
import BrushIcon from '@material-ui/icons/Brush';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ColorizeIcon from '@material-ui/icons/Colorize';
import StorageIcon from '@material-ui/icons/Storage';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import ToysIcon from '@material-ui/icons/Toys';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import GridOnIcon from '@material-ui/icons/GridOn';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import WbCloudyIcon from '@material-ui/icons/WbCloudy';
import NatureIcon from '@material-ui/icons/Nature';
import VideocamIcon from '@material-ui/icons/Videocam';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import "../../sass/frame.sass"
import {data} from "../web"
import {Player} from './player'
import {iShot, iShotInfoArtist} from "../web"

interface iprops {
    shot: iShot
}

interface istate {
    cg_width: number
    left: number
    src: string
    dst: string
    cg: string
    my_work: string[]
    softwares: string[]
    resources: string[]
    description: string
    artists: iShotInfoArtist[]
    breakdown: boolean
    active: boolean
    src_ready: boolean
    dst_ready: boolean
    cg_ready: boolean
}

export class Frame extends Component<iprops, istate>
{
    private player_result: Player
    private player_original: Player
    private player_breakdown: Player

    constructor(props: iprops) {
        super(props)

        let src = data.static + '/' + props.shot.image_src
        let dst = data.static + '/' + props.shot.image_dst

        if (!props.shot.image_src)
            src = dst

        if (!props.shot.image_dst)
            dst = src

        this.state = {
            cg_width: 50,
            left: 0,
            src: src,
            dst: dst,
            cg: props.shot.image_cg ? data.static + '/' + props.shot.image_cg : null,
            my_work: props.shot.info.my_work,
            softwares: props.shot.info.softwares,
            resources: props.shot.info.resources,
            description: props.shot.info.description,
            artists: props.shot.info.artists,
            breakdown: props.shot.image_src && props.shot.image_dst ? true : false,
            active: false,
            src_ready: src ? false : true,
            dst_ready: dst ? false : true,
            cg_ready: props.shot.image_cg ? false : true
        }
    }

    componentDidMount() {
        // espera que carguen las imagenes

        const src_img = new Image();
        const dst_img = new Image();
        const cg_img = new Image();

        src_img.src = this.state.src
        src_img.onload = () => {
            this.setState({src_ready: true})
        }

        dst_img.src = this.state.dst
        dst_img.onload = () => {
            this.setState({dst_ready: true})
        }

        cg_img.src = this.state.cg
        cg_img.onload = () => {
            this.setState({cg_ready: true})
        }
    }

    mouse_over = (e: MouseEvent) => {

        const left = e.currentTarget.getClientRects()[0].left
        const x = e.clientX - left

        if (!this.props.shot.image_cg) {
            this.setState({
                left: x
            })

            return
        }

        const image_width = e.currentTarget.clientWidth
        const center = image_width / 2

        const max_cg_width = 150

        let cg_width = (x * max_cg_width) / center
        if (x > center)
            cg_width = ((image_width - x) * max_cg_width) / center

        this.setState({
            left: x - (cg_width / 2),
            cg_width: cg_width
        });
    }

    get_works = (works: string[]) => {

        return works && works.map((work: string, index: number) =>
            <div className='work' key={index} >
                {work == 'roto' && <div><GestureIcon /> Rotoscoping</div>}
                {work == 'chroma' && <div><ColorizeIcon /> Chroma Key</div>}
                {work == 'track' && <div><CenterFocusStrongIcon /> 2D Tracking</div>}
                {work == 'cam' && <div><CameraAltIcon /> Camera Tracking</div>}
                {work == 'painting' && <div><BrushIcon /> Painting</div>}
                {work == 'comp' && <div><WallpaperIcon /> Compositing</div>}
                {work == 'graphics' && <div><ToysIcon /> Graphics</div>}
                {work == 'animation' && <div><VpnKeyIcon /> Animation</div>}
                {work == 'hair' && <div><ShowChartIcon /> Hair & Fur</div>}
                {work == 'dynamic' && <div><ScatterPlotIcon /> Dynamic</div>}
                {work == 'texture' && <div><GridOnIcon /> Texturing</div>}
                {work == 'render' && <div><WbIncandescentIcon /> Lighting & Rendering</div>}
                {work == 'modeling' && <div><NatureIcon /> Modeling</div>}
                {work == 'volumes' && <div><WbCloudyIcon /> Volumes</div>}
                {work == 'projection' && <div><VideocamIcon /> Projection</div>}
            </div>
        )
    }

    render() {
        return (
            <div className="frame_div">
                {this.state.src_ready && this.state.dst_ready && this.state.cg_ready ?
                    <div
                        onMouseMove={this.mouse_over}
                        onMouseEnter={() => this.setState({active: true})}
                        onMouseLeave={() => this.setState({active: false})}
                        className="frame">
                        <img className="src"
                            src={this.state.active ? this.state.src : this.state.dst} />

                        {(this.state.breakdown && this.state.active) &&
                            <div className="dst"
                                style={{width: this.state.left + 'px'}}>
                                <img src={this.state.dst} />
                            </div>
                        }

                        {(this.props.shot.image_cg && this.state.active) &&
                            <div className='cg'
                                style={{left: this.state.left + 'px', width: this.state.cg_width + 'px'}}>
                                <img src={this.state.cg}
                                    style={{left: -this.state.left + 'px'}} />
                            </div>
                        }
                    </div>
                    :
                    <div className='loading'>
                        <div>
                            <AutorenewIcon />
                            <h1>LOADING</h1>
                        </div>
                    </div>
                }
                <div className='info'>
                    <div className="player_buttons">
                        <button disabled={this.props.shot.video_original ? false : true}
                            onClick={() => this.player_original.show()}>
                            <PhotoCameraIcon />Play Original Plate
                        </button>
                        <button
                            disabled={this.props.shot.video_breakdown ? false : true}
                            onClick={() => this.player_breakdown.show()}>
                            <CompareIcon />Play Breakdown
                        </button>
                        <button
                            className='play_result'
                            disabled={this.props.shot.video_result ? false : true}
                            onClick={() => this.player_result.show()}>
                            <PlayArrowIcon />Play Result
                        </button>
                    </div>
                    <div className="bottom">
                        {this.state.description && <div><h1>DESCRIPTION:</h1> <p>{this.state.description}</p></div>}
                        <div>
                            <h1>SOFTWARES USED:</h1>
                            <div>
                                {
                                    this.state.softwares && this.state.softwares.map((software: string) =>
                                        <div className='software' key={software}>
                                            {software == 'nuke' && <div><img src={data.static + "/images/nuke.png"} />Nuke</div>}
                                            {software == 'houdini' && <div><img src={data.static + "/images/houdini.png"} />Houdini</div>}
                                            {software == 'after' && <div><img src={data.static + "/images/after.png"} />After Effect</div>}
                                            {software == 'maya' && <div><img src={data.static + "/images/maya.png"} />Maya</div>}
                                            {software == 'blender' && <div><img src={data.static + "/images/blender.png"} />Blender</div>}
                                            {software == 'cinema' && <div><img src={data.static + "/images/cinema.png"} />Cinema 4D</div>}
                                            {software == 'photoshop' && <div><img src={data.static + "/images/photoshop.png"} />Photoshop</div>}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div>
                            <h1>MY WORK:</h1>
                            <div>
                                {this.get_works(this.state.my_work)}
                            </div>
                        </div>

                        <div>
                            <h1>RESOURCES:</h1>
                            <div>
                                {
                                    this.state.resources && this.state.resources.map((resource: string) =>
                                        <div key={resource} className='resource'>
                                            {resource == 'footage' && <div><CameraAltIcon /> Footage</div>}
                                            {resource == 'stock' && <div><StorageIcon /> Stock</div>}
                                            {resource == 'cg' && <div><LaptopMacIcon /> CG</div>}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {this.state.artists && this.state.artists.length > 0 &&
                            <div>
                                <h1>OTHER ARTISTS:</h1>
                                <div>
                                    {
                                        this.state.artists.map((art: iShotInfoArtist) =>
                                            <div className='artist' key={art.name}>
                                                <h2>{art.name}:</h2>
                                                {this.get_works(art.work)}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>


                {this.props.shot.video_result &&
                    <Player
                        ref={player => this.player_result = player}
                        video={data.static + '/' + this.props.shot.video_result} />
                }

                {this.props.shot.video_breakdown &&
                    <Player
                        ref={player => this.player_breakdown = player}
                        video={data.static + '/' + this.props.shot.video_breakdown} />
                }

                {this.props.shot.video_original &&
                    <Player
                        ref={player => this.player_original = player}
                        video={data.static + '/' + this.props.shot.video_original} />
                }
            </div>
        )
    }
}
