import React, {Component} from "react"

import {BlogItem} from './blog_item'
import {data, iBlog} from "../web"

import "../../sass/blog.sass"

interface iprops {
}

interface istate {
}

export class Blog extends Component<iprops, istate>
{
    constructor(props: iprops) {
        super(props)
    }

    render() {
        return (
            <div className='blog'>
                <ul className="projects_list">
                    {
                        data.blog.map((blog: iBlog) =>
                            <BlogItem
                                key={blog.title}
                                blog={blog}
                            />
                        )
                    }
                </ul>
            </div>
        )

    }
}
