import React, {Component} from "react"
import {data, iBlog} from "../web"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

interface iprops {
    blog: iBlog
}

interface istate {
}

export class BlogItem extends Component<iprops, istate>
{
    render() {
        let count = this.props.blog.thumbnails ? this.props.blog.thumbnails : 1
        const thumbnails_count = Array.from({length: count}, (_, index) => index + 1)

        return (
            <div className='blog_item'>
                <div className='center'>
                    <div className='img_container'>
                        {thumbnails_count.map(index =>
                            <img key={index}
                                src={data.static + '/blog/thumbnails/'
                                    + this.props.blog.name + '_' + index + '.jpg'}>
                            </img>)
                        }
                    </div>
                    <div className='details'>
                        <div className='titles'>
                            <h1>{this.props.blog.title}</h1>
                            <h2>( {this.props.blog.subtitle} )</h2>
                        </div>
                        <p>{this.props.blog.think}</p>
                    </div>
                </div>
                <div className='bar'>
                    <div className="right">
                        <p>By <b>Francisco Contreras</b></p><div>|</div>
                        {this.props.blog.date} <div>|</div>
                        <a target='_blank' href={this.props.blog.showing}>Showing</a>
                    </div>
                    <div className='left'>
                        <CloudDownloadIcon/>
                        <a target='_blank' href={this.props.blog.link}>Download</a> 
                    </div> 
                </div>
            </div>
        )

    }
}
