import React, {Component} from "react"

import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';

import "../../sass/project.sass"
import {data, iProject, iShot} from "../web"
import {Frame} from "./frame"
import {Player} from './player'

interface iprops {
    project: iProject
}

interface istate {
}

export class Project extends Component<iprops, istate>
{
    private info: any
    private player: Player

    constructor(props: iprops) {
        super(props)

        this.info = this.props.project.info
    }

    shots_sorted = (): iShot[] => {

        let shots: iShot[] = []

        if (this.props.project.shots)
            this.props.project.shots.map((shot: iShot) =>
                shots.push(shot)
            )

        shots.sort((a, b) => (a.info.priority < b.info.priority) ? 1 : -1)

        return shots
    }

    render() {
        let tag = this.info.tags[0]
        tag = tag.charAt(0).toUpperCase() + tag.slice(1)

        let producers = ''
        this.info.producers.map((producer: string) =>
            producers += producer + ' -> '
        )
        producers = producers.slice(0, -4)

        return (
            <div className="project">
                <div className='project_header'>
                    <div className='img_div'>
                        <img src={data.static + '/' + this.props.project.slide} />
                    </div>
                    <div className='content'>
                        <div className='project_info'>
                            <h1>{this.info.title.toUpperCase()}</h1>
                            <h3>Type: {tag}</h3>
                            <h3>Year: {this.info.year}</h3>
                            {this.info.director && <h3>Director: {this.info.director}</h3>}
                            <h3>VFX Producers: {producers}</h3>
                            <h3>Country: {this.info.country}</h3>
                            <h3>Shots: {this.info.shots}{!this.info.exactshots && '+'}</h3>
                        </div>
                        {this.props.project.video &&
                            <div className='play'>
                                <button onClick={() => this.player.show()}>
                                    <SlowMotionVideoIcon />
                                </button>
                            </div>
                        }
                    </div>
                </div>
                {this.info.description && <p>this.info.description</p>}
                <ul className='shots_list'>
                    {
                        this.shots_sorted().map((shot: iShot, index: number) =>
                            <Frame
                                key={'shot_' + index}
                                shot={shot} />
                        )
                    }
                </ul>
                <Player
                    ref={player => this.player = player}
                    video={data.static + '/' + this.props.project.video} />
            </div>
        )
    }
}
