import React, {Component} from "react"
import {Link, NavLink} from "react-router-dom";

import DashboardIcon from '@material-ui/icons/Dashboard';
import StyleIcon from '@material-ui/icons/Style';
import TheatersIcon from '@material-ui/icons/Theaters';
import MovieIcon from '@material-ui/icons/Movie';
import CreateIcon from '@material-ui/icons/Create';

import "../../sass/nav.sass"

interface iprops {
}

interface istate {
}

export class Nav extends Component<iprops, istate>
{
    constructor(props: any) {
        super(props)
    }

    render() {

        let dashboard_text = ''
        const pathname = window.location.pathname

        if (pathname == '/' || pathname == '/dashboard')
            dashboard_text = 'dashboard'

        return (
            <div className="nav" text={dashboard_text}>
                <ul>
                    <li>
                        <NavLink to="/dashboard"><DashboardIcon/>HOME</NavLink>
                    </li>
                    <li>
                        <NavLink to="/projects/all"><MovieIcon/>FILMOGRAPHY</NavLink>
                    </li>
                    <li>
                        <NavLink to="/skill"><StyleIcon/>PROFILE</NavLink>
                    </li>
                    <li>
                        <NavLink to="/blog"><CreateIcon/>BLOG</NavLink>
                    </li>
                    <li>
                        <NavLink to="/timeline/2022"><TheatersIcon/>TIMELINE</NavLink>
                    </li>
                </ul>
            </div>
        )
    }
}
