import React, {Component} from "react"

import "../../sass/downloads.sass"

interface iprops {
}
interface istate {
}

export class Downloads extends Component<iprops, istate>
{
    constructor(props: iprops) {
        super(props)
    }

    render() {
        return (
            <div className="downloads">
                Downloads
            </div>
        )
    }
}
